@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  background-color: $grey11;
  overflow: hidden;
  padding-bottom: 10px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding-top: 7px;
  }
  @include media-breakpoint-down(xs) {
    height: 400px;
  }
  @include media-breakpoint-only(sm) {
    height: 432px;
  }
  @include media-breakpoint-only(md) {
    height: 497px;
  }
  @include media-breakpoint-only(lg) {
    height: 650px;
    padding-top: 120px;
  }
  @include media-breakpoint-up(xl) {
    height: 738px;
    padding-top: 3px;
  }
}

.image {
  left: 0;
  opacity: .2;
  position: absolute;
  top: 0;
}

.rowWrapper {
  align-items: center;
  height: 100%;
  position: relative;
}

.title {
  color: $white;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 1.38;
  }
  @include media-breakpoint-only(md) {
    font-size: 42px;
    line-height: 1.52;
  }
  @include media-breakpoint-up(lg) {
    font-size: 75px;
    line-height: 1.12;
  }
}

.description {
  color: $white;
  font-size: 16px;

  @include media-breakpoint-down(xs) {
    line-height: 1.75;
  }
  @include media-breakpoint-up(sm) {
    line-height: 1.5;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 21px;
  }
  @include media-breakpoint-only(md) {
    margin-top: 13px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 35px;
  }
}

.button {
  height: 50px;

  @include media-breakpoint-down(xs) {
    min-width: 155px;
  }
  @include media-breakpoint-only(sm) {
    min-width: 185px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 31px;
  }
  @include media-breakpoint-only(md) {
    margin-top: 26px;
    min-width: 211px;
  }
  @include media-breakpoint-only(lg) {
    margin-top: 33px;
    min-width: 170px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 51px;
    min-width: 200px;
  }
}
