@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-down(sm) {
    padding-bottom: 70px;
  }
  @include media-breakpoint-only(md) {
    padding-bottom: 20px;
  }
  @include media-breakpoint-only(lg) {
    padding-bottom: 3px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 20px;
  }
}

.title {
  color: $grey11;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 20px;
}

.bulletsContainer {
  @include media-breakpoint-down(sm) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.bullet {
  background-color: $grey85;
  border-radius: 50%;
  box-sizing: content-box;
  height: 6px;
  width: 6px;

  &:not(:last-child) {
    margin-right: 9px;
  }

  &Active {
    background-color: transparent;
    border: 3px solid $green;
  }
}

.slidesContainer {
  flex-wrap: nowrap;
  padding-bottom: 30px;
}

.articleCard {
  margin-top: 0;
  transition: transform 450ms ease-out 0s;

  @include media-breakpoint-down(xs) {
    flex: 0 0 85%;
    max-width: 85%;
    padding: 0 12.5px;
  }
  @include media-breakpoint-only(sm) {
    padding: 0 10px;
  }
}

.cardImageContainer {
  @include media-breakpoint-down(xs) {
    height: 180px;
  }
}

.cardTextContainer {
  @include media-breakpoint-down(xs) {
    padding-top: 23px;
  }
}

.banner {
  margin-top: 30px;
  transition: transform 450ms ease-out 0s;

  @include media-breakpoint-down(xs) {
    flex: 0 0 89%;
    max-width: 89%;
    padding: 60px 20px;
  }
  @include media-breakpoint-between(sm, md) {
    padding: 55px 20px;
  }
  @include media-breakpoint-only(lg) {
    padding: 80px 30px;
  }
  @include media-breakpoint-up(xl) {
    padding: 85px 35px;
  }
}
