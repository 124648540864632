@import "../breakpoints";
@import "../colors";
@import "../dimensions";

@mixin parallaxHeader {
  background-color: $grey11;
  max-height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;

  @include media-breakpoint-down(xs) {
    height: map-get($pageHeaderHeight, xs);
  }
  @include media-breakpoint-only(sm) {
    height: map-get($pageHeaderHeight, sm);
  }
  @include media-breakpoint-only(md) {
    height: map-get($pageHeaderHeight, md);
  }
  @include media-breakpoint-only(lg) {
    height: map-get($pageHeaderHeight, lg);
  }
  @include media-breakpoint-up(xl) {
    height: map-get($pageHeaderHeight, xl);
  }
}

@mixin parallaxHeaderPadding {
  padding-bottom: 5px;

  @include media-breakpoint-down(sm) {
    padding-top: map-get($headerWrapperHeight, sm);
  }
  @include media-breakpoint-between(md, lg) {
    padding-top: map-get($headerWrapperHeight, md);
  }
  @include media-breakpoint-up(xl) {
    padding-top: map-get($headerWrapperHeight, xl);
  }
}

@mixin parallaxContent {
  background-color: $white;
  position: relative;

  @include media-breakpoint-down(xs) {
    margin-top: map-get($pageHeaderHeight, xs);
  }
  @include media-breakpoint-only(sm) {
    margin-top: map-get($pageHeaderHeight, sm);
  }
  @include media-breakpoint-only(md) {
    margin-top: map-get($pageHeaderHeight, md);
  }
  @include media-breakpoint-only(lg) {
    margin-top: map-get($pageHeaderHeight, lg);
  }
  @include media-breakpoint-up(xl) {
    margin-top: map-get($pageHeaderHeight, xl);
  }
}
