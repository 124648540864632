@import "css-variables/breakpoints";
@import "css-variables/colors";
@import "css-variables/mixins/parallax";

.container {
  @include parallaxHeader;
}

.slide {
  @include parallaxHeaderPadding;

  align-items: center;
  display: flex;
  outline: none;
}

.image {
  left: 0;
  opacity: .2;
  position: absolute;
  top: 0;
}

.textContainer {
  color: $white;
  position: relative;
  text-align: left;
  width: 100%;
}

.title {
  font-weight: 700;
  line-height: 1.4;

  @include media-breakpoint-down(sm) {
    font-size: 32px;
    line-height: 1.38;
  }
  @include media-breakpoint-only(md) {
    font-size: 50px;
  }
  @include media-breakpoint-only(lg) {
    font-size: 60px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 70px;
  }
}

.description {
  @include media-breakpoint-down(xs) {
    line-height: 1.75;
    margin-top: 20px;
  }
  @include media-breakpoint-between(sm, lg) {
    line-height: 1.5;
    margin-top: 22px;
  }
  @include media-breakpoint-only(lg) {
    line-height: 1.5;
    margin-top: 33px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 1.7;
    margin-top: 35px;
  }
}

.button {
  height: 50px;

  @include media-breakpoint-down(xs) {
    min-width: 155px;
  }
  @include media-breakpoint-only(sm) {
    min-width: 185px;
  }
  @include media-breakpoint-only(md) {
    min-width: 211px;
  }
  @include media-breakpoint-only(lg) {
    min-width: 170px;
  }
  @include media-breakpoint-up(xl) {
    min-width: 200px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 32px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 51px;
  }
}

.arrow {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @include media-breakpoint-up(md) {
    bottom: 40px;
    color: $white;
    height: 38px;
    top: auto;
    width: 23px;

    > svg {
      height: 38px;
      width: 23px;
    }
  }
}

.arrowLeft {
  left: auto;

  @include media-breakpoint-down(lg) {
    right: 100px;
  }
  @include media-breakpoint-up(xl) {
    right: 160px;
  }
}

.arrowRight {
  @include media-breakpoint-down(lg) {
    right: 40px;
  }
  @include media-breakpoint-up(xl) {
    right: 100px;
  }
}

.dots {
  width: auto;

  @include media-breakpoint-down(sm) {
    bottom: 25px;
    left: auto;
    right: 0;
  }
  @include media-breakpoint-up(md) {
    bottom: 40px;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media-breakpoint-between(sm, lg) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 100px;
    padding-right: 100px;
  }

  li {
    margin: 3px 8px 3px 3px;
  }
}

// Hack for mobile landscape view to prevent button hiding.
@media (max-height: 400px) {
  .title {
    flex-basis: 100%;
    margin-left: 0;
    max-width: 100%;
  }

  .descriptionCol {
    flex-basis: 100%;
    margin-left: 0;
    max-width: 100%;
  }

  .description {
    margin-left: 0;
    margin-top: 10px;
  }

  .button {
    margin-top: 10px;
  }
}
