@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
  }
  @include media-breakpoint-only(md) {
    padding: 100px 0 80px;
  }
  @include media-breakpoint-only(lg) {
    padding: 122px 0 64px;
  }
  @include media-breakpoint-up(xl) {
    padding: 100px 0 70px;
  }
}

.newsItem {
  font-weight: 700;
  padding-bottom: 50px;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row-reverse;
  }

  @include media-breakpoint-only(md) {
    padding-bottom: 70px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 80px;
  }
}

.title {
  color: $grey11;
  display: block;

  @include media-breakpoint-down(xs) {
    padding-bottom: 20px;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 1 85%;
    padding-right: 8.5%;
  }

  @include media-breakpoint-down(sm) {
    font-size: 22px;
    line-height: 32px;
  }
  @include media-breakpoint-only(md) {
    flex-basis: 72%;
    font-size: 32px;
    line-height: 48px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 42px;
    line-height: 64px;
  }
  @include media-breakpoint-only(lg) {
    flex-basis: 69%;
  }
  @include media-breakpoint-up(xl) {
    flex-basis: 70%;
  }

  &:hover {
    color: $green;
  }
}

.dateBlock {
  color: $grey11;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 22px;
  }
  @include media-breakpoint-up(md) {
    font-size: 22px;
    line-height: 32px;
  }

  @include media-breakpoint-up(sm) {
    flex: 0 1 37%;
  }
  @include media-breakpoint-only(sm) {
    padding-top: 10px;
  }
  @include media-breakpoint-between(sm, md) {
    padding-left: 8.5%;
  }
  @include media-breakpoint-only(md) {
    padding-top: 20px;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 8.7%;
    padding-top: 23px;
  }
}


