@import "css-variables/breakpoints";
@import "css-variables/colors";

.container {
  @include media-breakpoint-down(xs) {
    margin-bottom: 70px;
  }
  @include media-breakpoint-only(sm) {
    margin-bottom: 59px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: 70px;
    padding-right: 0;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: 150px;
    margin-top: 152px;
    padding-right: 18px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 196px;
  }
  @include media-breakpoint-only(lg) {
    margin-bottom: 190px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 203px;
  }
}

.link {
  color: $grey11;
  font-weight: 700;
  margin-right: .3em;

  @include media-breakpoint-down(sm) {
    font-size: 22px;
  }
  @include media-breakpoint-only(md) {
    font-size: 32px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 42px;
  }

  @include media-breakpoint-down(xs) {
    line-height: 1.82;
  }
  @include media-breakpoint-only(sm) {
    line-height: 2;
  }
  @include media-breakpoint-up(md) {
    line-height: 1.53;
  }

  &:hover {
    color: $green;
  }
}
