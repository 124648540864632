// Basic
$white: #fff;
$black: #000;

//Primary
$primary: #fd7e00;
$primaryDark: #c46200;
$primaryLight: #feb266;

$orange: #fd7e00;
$lightOrange: #fda73f;

//Secondary
$secondary: #99ba14;
$secondaryDark: #78930b;
$secondaryLight: #ccdc89;

$green: #99ba14;
$yellowGreen: #d6ea6c;
$lightGreen: #B5D334;

// Grey
$grey: #4D4F53;
$grey11: #1d1d1d;
$grey20: #333;
$grey30: #45474d;
$grey40: #666;
$grey50: #808080;
$grey53: #888;
$grey55: #8b8b8b;
$grey58: #939393;
$grey61: #9d9d9d;
$grey80: #ccc;
$grey85: #d9d9d9;
$grey86: #ddd;
$grey88: #e0e0e0;
$grey90: #e5e5e5;
$grey92: #eaeaea;
$grey95: #f2f2f2;
$grey96: #f4f4f4;
$grey98: #f9f9f9;
$transparentBlack13: rgba($black, .13);
$transparentBlack15: rgba($black, .15);
$inactiveGrey: #c2c6cb;
$placeholderGrey: #83868b;

// Toasts

$lightSuccess: #f7f9ec;
$lightWarning: #fff0e0;
$lightError: #fff3f3;

// Other
$errorRed: #e22b28;
$yellow: #f7bf1d;
$yellowDark: #ce9900;
$red: #d12929;
$twitterBlue: #56acef;
$careersRed: #ea5f0b;
$careersBlue: #2ebce7;
$careersEmerald: #50b47c;
$darkBlue: #101840;
